import React from 'react';
import styled from 'styled-components';
import background from "../images/background.jpg"

const Section = styled.section`
  background: url(${background});
  /* position: relative; */
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  margin-top: -80px;
`

const Container = styled.div`
  max-width: 900px;
  text-align: center;
`

const Title = styled.h2`
  font-size: 5em;
  color: #fff;
  @media screen and (max-width: 960px){
    font-size: 3em;
  }
  @media screen and (max-width: 480px){
    font-size: 2.2em;
  }
`

const Text = styled.p`
  font-size: 1em;
  color: #fff;
`
const LinkBtn = styled.a`
  font-size: 1em;
  color: #000;
  background: gold;
  display: inline-block;
  padding: 10px 30px;
  margin-top: 20px;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 2px;
  transition: 0.5s;
  border-radius: 1px;

  &:hover{
  letter-spacing: 6px;
  }
`

const Banner = () => {
  return(
  <Section id="home">
    <Container>
      <Title>Stolarstwo od 1978</Title>
      <Text>Stolarstwo z pasją. <br /> Witamy! </Text>
      <LinkBtn href="#gallery" >Galeria</LinkBtn>
    </Container>
  </Section>
  )
};

export default Banner;