import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FaBars, FaTimes } from 'react-icons/fa'
// import { IconContext } from 'react-icons/lib'
import { Link } from 'gatsby';
import logo from "../images/logo.png"
// import { StaticImage } from 'gatsby-plugin-image';


const Nav = styled.nav`
  background: ${({ active }) =>
    active
      ? "#fff"
      : "linear-gradient(to bottom,  rgba(255,255,255, 0.9) 0%, rgba(255,255,255,0) 100%)"};
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 999;

  @media screen and (max-width: 960px){
    background: ${({ click })=> (click ? "#fff" : "linear-gradient(to bottom,  rgba(255,255,255, 0.9) 0%, rgba(255,255,255,0) 100%)")};
    transition: 0.8s all ease;
  }
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
  z-index: 1;
  width: 100%;
  max-width: 1000px;
`

const NavLogoLink = styled(Link)`
  color: #141414;
  justify-self: flex-start;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
`

const NavLogo = styled.img`
  margin: 0 0.5rem 0 1rem;
  width: 218px;
  @media screen and (max-width: 960px){
    width: 180px;
  }
`

const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 960px){
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`

const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;

  @media screen and (max-width: 960px){
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: ${({click}) =>(click ? "100%" : "-1000px")};
    opacity: 1;
    transition: all 0.2s ease;
    background: #fff;
  }
`

const NavItem = styled.li`
  height: 80px;

  @media screen and (max-width: 960px){
    width: 100%;
}
`

const NavLinks = styled(Link)`
  color: #141414;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  font-family: 'Ubuntu', sans-serif;
  white-space: nowrap;

  @media screen and (max-width: 960px){
    text-align: center;
    font-size: 22px;
    padding: 2rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &:hover {
    color: gold;
    transition: all 0.3s ease;
  }
  
`

const Navbar = () => {
  const [click, setClick] = useState(false);
  const [scroll, setScroll] = useState(false);

  const handleClick = () => setClick(!click);
 
  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScroll(true)
    } else {
      setScroll(false)
    }
  }

  useEffect(() => {
    changeNav()
    window.addEventListener("scroll", changeNav)
  }, []);
  
  return(
    <Nav active = { scroll } click = { click }>
      <Container>
        <NavLogoLink to="/">
          <NavLogo src={logo} alt="logo"/>
        </NavLogoLink>
        <MobileIcon onClick={handleClick}>
          {click ? <FaTimes /> : <FaBars />}
        </MobileIcon>
        <NavMenu onClick={handleClick} click={click}>
          <NavItem>
            <NavLinks to="../#home">Witamy</NavLinks>
          </NavItem>
          <NavItem>
            <NavLinks to="../#about">O firmie</NavLinks>
          </NavItem>
          <NavItem>
            <NavLinks to="../#gallery">Galeria</NavLinks>
          </NavItem>
          <NavItem>
            <NavLinks to="../#offer">Oferta</NavLinks>
          </NavItem>
          <NavItem>
            <NavLinks to="../#testimonials">Opinie</NavLinks>
          </NavItem>
          <NavItem>
            <NavLinks to="../#contact">Kontakt</NavLinks>
          </NavItem>
        </NavMenu>
      </Container>
    </Nav>
  )
};

export default Navbar;
