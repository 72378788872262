import React from 'react';
import styled from 'styled-components';
import background from "../images/body_background.jpg"
import jacek from "../images/jacek.png"
import piotr from "../images/piotr.png"
import kamil from "../images/kamil.png"


const Section = styled.section`
  padding: 100px;
  background: url(${background});
  background-attachment: fixed;
  color: #fff;
  @media screen and (max-width: 920px) {
    padding: 80px 20px 20px 20px;
  }
`

const TitleBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const Title = styled.h2`
  font-size: 2em;
  font-weight: 300;
  @media screen and (max-width: 920px) {
    font-size: 1.8em;
    line-height: 1.5em;
    margin-bottom: 15px;
    font-weight: 300;
  }
`

const GoldSpan = styled.span`
  color: gold;
  font-weight: 700;
  font-size: 1.5em;
`

const Content = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 20px;
`

const Box = styled.div`
  width: 300px;
  margin: 20px;
  padding: 15px 20px 10px 20px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const ImgBox = styled.div`
  position: relative;
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
  border-radius: 50%;
  overflow: hidden;
`
const Img = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const TextBox = styled.div`
  text-align: center;
`
const Text = styled.p`
  color: #666;
  font-style: italic;
`
const Name = styled.h3`
  margin-top: 12px;
  color: #111;
  font-size: 1em;
  color: gold;
  font-weight: 600;
`

const Testimonials = () => {
  return(
    <Section id="testimonials">
      <TitleBox>
        <Title><GoldSpan>O</GoldSpan>pinie naszych klientów</Title>
      </TitleBox>
      <Content>
        <Box>
          <ImgBox>
            <Img src={jacek} alt="jacek" />
          </ImgBox>
          <TextBox>
            <Text>
              Profesjonalna, fachowa robota. Polecam!
            </Text>
            <Name>Jacek</Name>
          </TextBox>
        </Box>
        <Box>
          <ImgBox>
            <Img src={piotr} alt="piotr" />
          </ImgBox>
          <TextBox>
            <Text>
              Chciałem przemalować meble w pokoju dziecka. Stolarz nie miał wolnych terminów, ale powiedział mi jak sam sobie mogę poradzić. Spokojnie i kompetentnie wytłumaczył mi jaką farbę kupić, jak ją rozcieńczyć, jak przygotować drzwi beli i jak je malować. Miły gest!
            </Text>
            <Name>Piotr</Name>
          </TextBox>
        </Box>
        <Box>
          <ImgBox>
            <Img src={kamil} alt="kamil" />
          </ImgBox>
          <TextBox>
            <Text>
              Fachowa robota, tak jak ustalono.
            </Text>
            <Name>Kamil</Name>
          </TextBox>
        </Box>
      </Content>
    </Section>
  ) 
};

export default Testimonials;
