import React from 'react';
import styled from 'styled-components';
import background from "../images/body_background.jpg"
import image from "../images/offer.jpg"

const Section = styled.section`
  padding: 100px 100px 0px 100px;
  background: url(${background});
  background-attachment: fixed;
  display: flex;
  justify-content: center;
  flex-wrap: wrap-reverse;
  flex-direction: row-reverse;
  color: #fff;

  @media screen and (max-width: 920px) {
    padding: 80px 20px 0 20px;
  }
`

const Row = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`

const Column = styled.div`
  position: relative;
  width: 48% ;
  @media screen and (max-width: 820px) {
    position: relative;
    width: 100%;
  }
`

const ImgBox = styled.div`
  height: 300px;
  margin-top: 20px;
`

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 2px;
`

const TitleBox = styled.div`
  
`

const Title = styled.h2`
  text-align: center;
  font-size: 2em;
  font-weight: 300;
  @media screen and (max-width: 920px) {
    font-size: 1.8em;
    line-height: 1.5em;
    margin-bottom: 15px;
    font-weight: 300;
  }
`

const GoldSpan = styled.span`
  color: gold;
  font-weight: 700;
  font-size: 1.5em;
`

const TitleM = styled.h3`
  
`

const List = styled.ul`
  margin-left: 20px;
`

const ListItem = styled.li`
  
`

const Offert = () => {
  return(
    <Section id="offer">
      <Row>
        <Column>
          <ImgBox>
            <Image src={image} alt="Warsztat"/>
          </ImgBox>
        </Column>
        <Column>
          <TitleBox>
            <Title>Nasza <GoldSpan>O</GoldSpan>ferta</Title>
          </TitleBox>
          <TitleM>Wykonujemy:</TitleM>
          <List>
            <ListItem>schody</ListItem>
            <ListItem>drzwi</ListItem>
            <ListItem>altany</ListItem>
            <ListItem>balustrady</ListItem>
            <ListItem>balkony</ListItem>
            <ListItem>ogrodzenia</ListItem>
            <ListItem>garaże drewniane</ListItem>
            <ListItem>okna</ListItem>
            <ListItem>parapety</ListItem>
            <ListItem>meble z drewna Litego</ListItem>
          </List>
          <TitleM>Na życzenie klienta wykonujemy inne dodatkowe usługi.</TitleM>
        </Column>
      </Row>
    </Section>
  ) 
};

export default Offert;
