import React from 'react';
import styled from 'styled-components';
import firmaImg from '../images/o_firmie.jpg'

const Section = styled.section`
  padding: 100px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap-reverse;
  flex-direction: row-reverse;
  @media screen and (max-width: 920px) {
    padding: 80px 20px;
  }
`

const Row = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 920px) {
    flex-direction: column;
  }
`

const Column = styled.div`
  position: relative;
  width: 48% ;
  @media screen and (max-width: 920px) {
    position: relative;
    width: 100%;
  }
`

const Title = styled.h2`
  color: #111;
  font-size: 2em;
  font-weight: 300;
  @media screen and (max-width: 920px) {
    font-size: 1.8em;
    line-height: 1.5em;
    margin-bottom: 15px;
    font-weight: 300;
  }
`

const GoldSpan = styled.span`
  color: gold;
  font-weight: 700;
  font-size: 1.5em;
`

const Text = styled.p`
  
`

const TitleM = styled.h4`
  text-align: center;
`

const List = styled.ul`
  margin-left:20px;
  padding-bottom: 10px;
`

const ListItem = styled.li`
  
`

const ImageBox = styled.div`
  position: relative;
  width: 100%;
  /* min-height: 300px; */
  height: 100%;
  @media screen and (max-width: 920px) {
    height: 300px;
    margin-top: 20px;
  }
`

const Image = styled.img`
  position: absolute;
  top: 10;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 2px;
`

const About = () => {
  return(
    <Section id="about">
      <Row>
        <Column>
          <Title>O <GoldSpan>F</GoldSpan>irmie</Title>
          <Text>Nasza firma oferuje kompleksowe usługi stolarskie. Stoi za nami 40 lat doświadczenia w wykonywaniu okien, drzwi, schodów, altan, balustrad oraz wiele innych. Zajmujemy się usługami w dziedzinie stolarstwa. Wykonujemy usługi typowe i nietypowe na życzenie klienta. Każde zamówienie jest realizowane według upodobań zleceniodawcy.</Text>
          <TitleM>Klientom gwarantujemy:</TitleM>
          <List>
            <ListItem>profesjonalne wykonanie</ListItem>
            <ListItem>solidną realizacje zamówień</ListItem>
            <ListItem>szeroką gamę produktów/usług</ListItem>
          </List>
          <Text>Zasób wiedzy jak i pasja zawodowa pozwala nam realizować tematy trudne i zaspokajać gusty najbardziej wymagających klientów.</Text>
          <TitleM>Zapraszamy do współpracy...</TitleM>
        </Column>
        <Column>
          <ImageBox>
            <Image src={firmaImg} />
          </ImageBox>
        </Column>
       </Row>
    </Section>
    )

};

export default About;