import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import altanki from "../images/galery/altanki/slide/altanki.jpg";
import balustrady from "../images/galery/balustrady/slide/balustrady.jpg";
import drzwi from "../images/galery/drzwi/slide/drzwi.jpg";
import meble from "../images/galery/meble/slide/meble.jpg";
import ogrodzenia from "../images/galery/ogrodzenia/slide/ogrodzenia.jpg";
import pomosty from "../images/galery/pomosty/slide/pomosty.jpg";
import rozmaite from "../images/galery/rozmaite/slide/rozmaite.jpg";
import schody from "../images/galery/schody/slide/schody.jpg";


const Section = styled.section`
  padding: 100px;
  background: #fff;
  @media screen and (max-width: 920px) {
    padding: 80px 20px;
  }
`

const TitleBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const Title = styled.h2`
  color: #111;
  font-size: 2em;
  font-weight: 300;
  @media screen and (max-width: 920px) {
    font-size: 1.8em;
    line-height: 1.5em;
    margin-bottom: 15px;
    font-weight: 300;
  }
`

const GoldSpan = styled.span`
  color: gold;
  font-weight: 700;
  font-size: 1.5em;
`

const TitleText = styled.p`

`

const Content = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
`

const ImgLink = styled(Link)`
  text-decoration: none;
`

const Category = styled.div`
  width: 300px;
  margin: 15px;
  border: 15px solid #fff;
  box-shadow: 0 5px 35px rgba(0, 0, 0, 0.89);
  cursor: pointer;
  transition: 0.5s;
  
  &:hover{
    box-shadow: 0 5px 35px rgba(255, 230, 4, 0.69);
  }
`

const ImgBox = styled.div`
  position:relative;
  width: 100%;
  height: 250px;

  
`

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  animation: showMe 3s linear infinite 0s forwards;
  animation-play-state: paused;

`

const TextBox = styled.div`
  
`

const Text = styled.h3`
  font-weight: 400;
  color: #111;
`
const Gallery = () => {
  return(
    <Section id="gallery">
      <TitleBox>
        <Title><GoldSpan>G</GoldSpan>aleria</Title>
        <TitleText>Oto czym się zajmujemy:</TitleText>
      </TitleBox>
      <Content>
        <ImgLink to="/altanki">
        <Category>
          <ImgBox>
            <Image src={altanki} alt="altanki" />
          </ImgBox>
          <TextBox>
            <Text>Altanki i domki</Text>
          </TextBox>
        </Category>
        </ImgLink>
        <ImgLink to="/balustrady">
        <Category>
          <ImgBox>
            <Image src={balustrady} alt="balustrady" />
          </ImgBox>
          <TextBox>
            <Text>Blustrady</Text>
          </TextBox>
        </Category>
        </ImgLink>
        <ImgLink to="/drzwi">
        <Category>
          <ImgBox>
            <Image src={drzwi} alt="drzwi"/>
          </ImgBox>
          <TextBox>
            <Text>Drzwi i okna</Text>
          </TextBox>
        </Category>
        </ImgLink>
        <ImgLink to="/meble">
        <Category>
          <ImgBox>
            <Image src={meble} alt="meble"/>
          </ImgBox>
          <TextBox>
            <Text>Meble</Text>
          </TextBox>
        </Category>
        </ImgLink>
        <ImgLink to="/ogrodzenia">
        <Category>
          <ImgBox>
            <Image src={ogrodzenia} alt="ogrodzenia"/>
          </ImgBox>
          <TextBox>
            <Text>Ogrodzenia</Text>
          </TextBox>
        </Category>
        </ImgLink>
        <ImgLink to="/pomosty">
        <Category>
          <ImgBox>
            <Image src={pomosty} alt="pomosty"/>
          </ImgBox>
          <TextBox>
            <Text>Pomosty</Text>
          </TextBox>
        </Category>
        </ImgLink>
        <ImgLink to="/rozmaite">
        <Category>
          <ImgBox>
            <Image src={rozmaite} alt="rozmaite"/>
          </ImgBox>
          <TextBox>
            <Text>Rozmaite</Text>
          </TextBox>
        </Category>
        </ImgLink>
        <ImgLink to="/schody">
        <Category>
          <ImgBox>
            <Image src={schody} alt="schody"/>
          </ImgBox>
          <TextBox>
            <Text>Schody</Text>
          </TextBox>
        </Category>
        </ImgLink>
      </Content>
    </Section>
  )
};

export default Gallery;
