import React from "react"
import About from "../components/About"
import Banner from "../components/Banner"
import Contact from "../components/Contact"
import Gallery from "../components/Gallery"
import Offer from "../components/Offer"
import Testimonials from "../components/Testimonials"

import Layout from "./layout"
import Seo from "./seo"
import "./styles.css"

const IndexPage = () => (
  <Layout>
    <Seo title="Home"/>
    <Banner />
    <About />
    <Gallery />
    <Offer />
    <Testimonials />
    <Contact />
  </Layout>
)

export default IndexPage