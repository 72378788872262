import React from 'react';
import styled from 'styled-components';
import { SiGooglemaps } from "react-icons/si"
import { FaPhone, FaGoogle, FaFacebookF } from "react-icons/fa"
import { FiMail } from "react-icons/fi"

const Section = styled.section`
  padding: 100px;
  background: #111;
  color: #fff;
  @media screen and (max-width: 920px) {
    padding: 80px 20px;
  }
`

const TitleBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const Title = styled.h2`
  color: #fff;
  font-size: 2em;
  font-weight: 300;
  @media screen and (max-width: 920px) {
    font-size: 1.8em;
    line-height: 1.5em;
    margin-bottom: 15px;
    font-weight: 300;
  }
`

const GoldSpan = styled.span`
  color: gold;
  font-weight: 700;
  font-size: 1.5em;
`

const Titlep = styled.p`
  text-align: center;
`

const Content = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
`

const ContactInfoColumn = styled.div`
  min-width: 40%;
  align-items: flex-start;
`
const ColumnTitle = styled.h3`
  color: #fff;
  font-size: 1.2em;
  font-weight: 500;
  margin-bottom: 10px;
`

const ContactInfoBox = styled.div`
  position: relative;
`

const InfoBox = styled.div`
  position: relative;
  padding: 10px 0;
  display: flex;
`

const IconBox = styled.div`
  min-width: 40px;
  padding-top: 4px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  font-size: 24px;
`

const ContactInfoTextBox = styled.div`
  display: flex;
  margin-left: 20px;
  font-size: 16px;
  color: #fff;
  flex-direction: column;
  font-weight: 300;
`

const ContactTitle = styled.h3`
  font-weight: 500;
  color: gold;
  margin-bottom: 0;
`

const Link = styled.a`
  text-decoration: none;
  color: #fff;
`

const Text = styled.p`

`
const SocialBox = styled.div`
  display: flex;
  justify-content: space-around;
`
const FormColumn = styled.div`
  min-width: 60%;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
`

const Input = styled.input`
    margin-bottom: 20px;
  padding: 15px;
  font-size: 16px;
  background: transparent;
  border: none;
  outline: none;
  background: #222;
  color: #fff;
  
`

const InputBtn = styled.input`
  font-size: 1em;
  color: #000;
  background: gold;
  display: inline-block;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 2px;
  transition: 0.5s;
  max-width: 150px;
  font-weight: 500;
  border: none;
  cursor: pointer;
  padding: 15px;

  &:hover{
    letter-spacing: 6px;
  }
`

const Textarea = styled.textarea`
  margin-bottom: 20px;
  padding: 15px;
  font-size: 16px;
  background: transparent;
  border: none;
  outline: none;
  background: #222;
  color: #fff;
  resize: none;
  min-height: 200px;
`

const GoogleIcon = styled(FaGoogle)`
  margin-left: 8px;
`

const Contact = () => {
  return(
    <Section id="contact">
      <TitleBox>
        <Title><GoldSpan>K</GoldSpan>ontakt</Title>
        <Titlep>Przyjedź, zadzwoń, napisz... <br /> Zapraszamy!</Titlep>
      </TitleBox>
      <Content>
        <ContactInfoColumn>
          <ColumnTitle>Informacje</ColumnTitle>
          <ContactInfoBox>
            <InfoBox>
              <IconBox>
                <SiGooglemaps />
              </IconBox>
              <ContactInfoTextBox>
                <ContactTitle>Adres</ContactTitle>
                <Link href="https://goo.gl/maps/GCGex5YQg6iht4qD6"><Text>ul. Sosnowa 1,<br />Ustroń 43-450,<br />Polska</Text></Link>
              </ContactInfoTextBox>
            </InfoBox>
            <InfoBox>
              <IconBox>
                <FaPhone />
              </IconBox>
              <ContactInfoTextBox>
                <ContactTitle>Telefon</ContactTitle>
                <Text>+48 796 666 643</Text>
                <Text>+48 721 416 393</Text>
              </ContactInfoTextBox>
            </InfoBox>
          <InfoBox>
            <IconBox>
              <FiMail />
            </IconBox>
            <ContactInfoTextBox>
              <ContactTitle>Email</ContactTitle>
              <Link href="mailto:pilch@vp.pl"><Text>pilch@vp.pl</Text></Link>
              <Link href="mailto:biuro@stolarstwopilch.com"><Text>biuro@stolarstwopilch.com</Text></Link>
            </ContactInfoTextBox>
          </InfoBox>
          <SocialBox>
            <IconBox>
              <Link href="https://www.facebook.com/Stolarstwo-Pilch-Adam-243763419122568/?fref=ts"><FaFacebookF /></Link>
              <Link href="https://maps.app.goo.gl/UU15JPWWy7zJ5mST7"><GoogleIcon /></Link>
            </IconBox>
          </SocialBox>
        </ContactInfoBox>
      </ContactInfoColumn>
      <FormColumn>
        <Form>
          <ColumnTitle>Napisz wiadomość</ColumnTitle>
          <Input type="text" placeholder="Imię" />
          <Input type="email" placeholder="Email" />
          <Textarea placeholder="Wiadomość"></Textarea>
          <InputBtn type="submit" value="Wyślij" /> 
        </Form>
      </FormColumn>
    </Content>
  </Section>  
  ) 
};

export default Contact;
