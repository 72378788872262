import React from 'react';
import styled from 'styled-components';

const Section = styled.footer`
  padding: 8px 40px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  text-align: center;
  background: #000;
`

const Text = styled.p`
  color: #333;
  @media screen and (max-width: 920px) {
    font-size: 0.9em;  
  }
`

const Footer = () => {
  return(
    <Section>
      <Text>Copyright © {new Date().getFullYear()} | Stolarstwo Pilch Adam | Designed by Kamil Karchut</Text>
    </Section>
  ) 
};

export default Footer;